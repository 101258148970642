.card {
  border: none;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px rgb(200, 200, 200);
  box-shadow: 0 0 10px rgb(200, 200, 200);
}

.profile-cover {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 200px;
  width: 100%;
  padding-top: 140px;
}

.profile-image {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  margin: auto;
  padding-top: 65px;
  padding-left: 65px;
}

.profile-logo {
  width: 35px;
  height: 35px;
}
